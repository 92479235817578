<template>
  <div class="adress">
    <div class="banxin1">
      <div class="h2">{{$t('content.zhangjiakouChina')}}</div>
      <div class="navname1">
        <div v-for="(item, index) in swiperArr" :key="index" class="cityCardList1">
          <span v-if="index">|</span>
          <span class="cityCardName" v-bind:class="{ cityCardNameCurrent: index == currentCityIndex}" @click="gotoCityIndex(index)">{{item.title}}</span>
        </div>
      </div>
      <!-- 轮播 -->
      <div class="block">
        <el-carousel height="476px" @change="handleChange" ref="carousela">
          <el-carousel-item v-for="(item, index) in swiperArr" :key="index">
            <img v-lazy="baseUrl + item.pcImgUrl" alt="" class="imgone1" @click="goDetailzjk(item.id)"/>
            <h3 class="slcontent">{{item.briefInfo}}</h3>           
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--内容区  -->
      
      <!-- 浏览更多 -->
      <div class="btn" @click="more">{{$t('content.browseMore')}}</div>
    </div>
  </div>
</template>

<script>
import Swiperone from "../swiperone/swiperone";
export default {
  components: {
    Swiperone,
  },
  data() {
    return {
      cityCards: [],
      zgzjk:'中国张家口',
      qxq:'桥西区',
      clq:'崇礼区',
      hlx:'怀来县',
      ccx:'赤城县',
      zbx:'张北县',
      llgd:'浏览更多',
      currentCityIndex: 0,
      // isActive: "",
      swiperArr: [],
      
      baseUrl: "http://qiniu.image.citgroup.cn/hebei/zhangjiakou/cms",
    };
  },
  mounted() {
    this.home();
  },
  methods: {
    more(){
      this.$router.push('/cityname').catch(()=>{})
    },
    async home() {
      this.swiperArr = [];
      let resoult = await this.$http({
        url: "/api/featureApi/getCitys",
        method: "get",
      });
      this.swiperArr = resoult.data.data;
      console.log(this.swiperArr);
    },
    // 轮播改变回调
    handleChange(key1, key2){
      this.currentCityIndex = key1;
    },
    // 跳转到指定轮播
    gotoCityIndex(index){
      this.$refs.carousela.setActiveItem(index);
    },
    goDetailzjk(id) {
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/featureApi/getDetail/'+id
        },
      });    
    }
  },
};
</script>

<style>
/* 中国张家口 */
.adress {
  width: 100%;
  height: 798px;
  z-index: 1;
  position: absolute;
}
.adress .banxin1 {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  /* background-color: pink; */
  text-align: center;
}
.adress .banxin1 .h2 {
  height: 84px;
  line-height: 103px;
  /* background-color: green; */
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.adress .banxin1 .navname1 {
  /* width: 646px; */
  height: 50px;
  line-height: 57px;
  /* background-color: pink; */
  /* margin-left: 321px;
  margin-bottom: 37px;
  margin-top: 33px; */
  margin: 33px auto 37px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  cursor: pointer;
}
.cityCardName:hover{
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #e60012;
}
.cityCardNameCurrent{
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #e60012;
}
.cityCardList1 {
  /* width: 104px; */
  margin-right: 22px;
}
.cityCardName {
  margin-left: 32px;
}
/* 内容区 */
/* .banxin1 .block .content {
  width: 1032px;
  height: 44px;
  background-color: yellow;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
  margin: 38px 0px 55px 83px;
} */
/* 浏览更多 */
.banxin1 .btn {
  background: url(http://qiniu.image.citgroup.cn/hebei/zhangjiakou/icon/btn.png)
    no-repeat;
  width: 227px;
  height: 46px;
  line-height: 46px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #54413c;
  text-align: center;
  margin: 36px auto 0px;
  cursor: pointer;
}
/* 轮播 */
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.imgone1 {
  width: 1200px;
  height: 316px;
  cursor: pointer;
}
.slcontent {
  /* width: 1032px;
  height: 44px; */
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  /* line-height: 28px; */
  margin: 38px 0px 55px 83px;
 
}

</style>