<template>
  <div>
    <Swiper></Swiper>
    <Xwph></Xwph>
    <Mrtj></Mrtj>
    <Zl></Zl>
    <Bottombanner></Bottombanner>
  </div>
</template>

<script>
import Swiper from "../swiper/swiper";
import Xwph from "../xwph/xwph";
import Mrtj from "../mrtj/mrtj";
import Zl from "../zl/zl";
import Bottombanner from "../bottombanner/bottombanner";
export default {
  components: {
    Swiper,
    Xwph,
    Mrtj,
    Zl,
    Bottombanner
  },
};
</script>

<style>
</style>