<template>
  <div class="block">
    <el-carousel height="316px">
      <el-carousel-item v-for="(item, index) in swiperOneArr" :key="index">
        <img :src="baseUrl + item.pcImgUrl" alt="" class="imgone" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOneArr: [],
      baseUrl: "http://qiniu.image.citgroup.cn/hebei/zhangjiakou/cms",
    };
  },
  mounted() {
    this.home();
  },
  methods: {
    async home() {
      let resoult = await this.$http({
        url: "/api/featureApi/getCitys",
        method: "get",
      });
      // console.log(resoult);
      this.swiperOneArr = resoult.data.data;
    },
  },
};
</script>

<style>
/* 轮播 */
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.imgone {
  width: 1200px;
  height: 316px;
}
</style>