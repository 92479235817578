var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"xwph-banxin"},[_vm._l((_vm.jinriremen),function(item,index){return _c('div',{key:index,staticClass:"left",on:{"click":function($event){return _vm.jinri(item.id)}}},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:({src: _vm.baseUrl + item.imgUrl}),expression:"{src: baseUrl + item.imgUrl}",arg:"background-image"}],staticClass:"leftimg",style:({
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      })}),_c('div',{staticClass:"h3"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(item.briefInfo)+" ")])])}),_c('div',{staticClass:"right"},[_c('div',{staticClass:"t2"},[_vm._v(_vm._s(_vm.$t('content.newsRankings')))]),_c('div',{staticClass:"more",on:{"click":_vm.chakangd}},[_vm._v(_vm._s(_vm.$t('content.viewMore'))+">>")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"detailbox"},_vm._l((_vm.xinwenph),function(item,index){return _c('div',{key:index,staticClass:"detail",on:{"click":function($event){return _vm.jinri(item.id)}}},[_c('div',{staticClass:"num1"}),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:({src: _vm.baseUrl + item.imgUrl}),expression:"{src: baseUrl + item.imgUrl}",arg:"background-image"}],staticClass:"img",style:({
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          })}),_c('div',{staticClass:"detailbox-right"},[_c('div',{staticClass:"h4"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(item.briefInfo)+" ")])])])}),0)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }