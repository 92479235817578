<template>
  <!-- 热门线路 -->
  <div class="yjglrmxl">
    <div class="h2">{{ $t('content.hotLine') }}</div>
    <!-- <ul class="nav">
      <li>综合排序</li>
      <li>销量</li>
      <li>好评</li>
    </ul> -->
    <!-- 热门线路详情 -->
    <Rmxldetail></Rmxldetail>
  </div>
</template>

<script>
import Bus from "../../api/Bus";
import Rmxldetail from "../rmxldetail/rmxldetail";
export default {
  components: {
    Rmxldetail,
  },
};
</script>

<style>
.yjglrmxl {
  width: 1200px;
  /* height: 1200px; */
  /* background-color: sienna; */
  margin: 0 auto;
  margin-bottom: 50px;
}
.yjglrmxl .h2 {
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin: 105px auto 31px;
}
/* .yjgl .nav {
  width: 450px;
  height: 42px;
  background: teal;
  display: flex;
  justify-content: space-between;
  margin-bottom: 54px;
}
.yjgl .nav li {
  width: 142px;
  height: 42px;
  background: #ffeae8;
  opacity: 0.73;
  border-radius: 21px;
  text-align: center;
  line-height: 42px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
}
.yjgl .nav li:hover {
  color: #d1524e;
} */
</style>