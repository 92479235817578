<template>
  <!-- 大好河山 -->
  <div class="dhhs">
    <div class="h2">{{ $t('content.oneSBelovedMotherland') }}</div>
    <div class="list">
      <div class="img" @click="foodProducts" v-lazy:background-image="{src: 'http://qiniu.image.citgroup.cn/zhangjiakou/icon/mstc.jpg'}">
        <div class="tet">{{ $t('content.foodProducts') }}</div>
        <div class="imgContent">
          {{ $t('content.foodProductsContent') }}
        </div>
        <div class="btn">{{ $t('content.browseMore') }}</div>
      </div>
      <div class="img1" @click="culture" v-lazy:background-image="{src: 'http://qiniu.image.citgroup.cn/zhangjiakou/icon/whys.jpg'}">
        <div class="tet">{{ $t('content.cultureAndArt') }}</div>
        <div class="imgContent">
          {{ $t('content.cultureAndArtContent') }}
        </div>
        <div class="btn">{{ $t('content.browseMore') }}</div>
      </div>
      <div class="img2" @click="teseline" v-lazy:background-image="{src: 'http://qiniu.image.citgroup.cn/zhangjiakou/icon/tsxl.jpg'}">
        <div class="tet">{{ $t('content.characteristicLine') }}</div>
        <div class="imgContent">
          {{ $t('content.characteristicLineContent') }}
        </div>
        <div class="btn">{{ $t('content.browseMore') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      mes:'大好河山',
      food:'美食特产',
      more:'浏览更多',
      art:'文化艺术',
      line:'特色线路',
      content:'从美丽富饶的桑干河畔到水草丰盛的坝上草原，到处都有当地人百吃不厌、外地人稀罕的名品小吃。',
      content1:' 慷慨悲歌与粗犷豪放相交融， 具有浓厚时代气息和政治色彩。',
      content2:'望长城内外，游大好河山，草原牧歌经典旅游线大美坝上草原，尽享自由自在。'
    }
  },
  methods:{
    foodProducts(){
        this.$router.push('/public').catch(()=>{})
    },
     culture(){
        this.$router.push('/publicculture').catch(()=>{})
    },
    teseline(){
        this.$router.push('/teseline').catch(()=>{})
    }
  }
};
</script>

<style>
.dhhs {
  width: 1200px;
  height: 994px;
  margin: 798px auto 0px;
  /* background-color: yellowgreen; */
}
.dhhs .h2 {
  height: 84px;
  line-height: 103px;
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.dhhs .list {
  height: 536px;
  width: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  margin-top: 53px;
  cursor: pointer;
}
.dhhs .list .img {
  width: 377px;
  height: 536px;
  /* background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/mstc.jpg)
    no-repeat; */
  background-repeat: no-repeat;
  background-size: 377px 536px;
  position: relative;
}
.dhhs .list .img1 {
  width: 377px;
  height: 536px;
  /* background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/whys.jpg)
    no-repeat; */
  background-repeat: no-repeat;
  background-size: 377px 536px;
  position: relative;
}
.dhhs .list .img2 {
  width: 377px;
  height: 536px;
  /* background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/tsxl.jpg)
    no-repeat; */
  background-repeat: no-repeat;
  background-size: 377px 536px;
  position: relative;
}
.dhhs .list .img .tet,
.dhhs .list .img1 .tet,
.dhhs .list .img2 .tet {
  margin-top: 578px;
  /* margin-left: 144px; */
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.dhhs .list .img .imgContent,
.dhhs .list .img1 .imgContent,
.dhhs .list .img2 .imgContent {
  width: 326px;
  height: 82px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
  margin: 34px auto 0px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;/*超出3行部分显示省略号，去掉该属性 显示全部*/
        -webkit-box-orient: vertical;
}
.dhhs .list .img .btn,
.dhhs .list .img1 .btn,
.dhhs .list .img2 .btn {
  background: url(http://qiniu.image.citgroup.cn/hebei/zhangjiakou/icon/btn.png)
    no-repeat;
  width: 227px;
  height: 46px;
  line-height: 46px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #54413c;
  text-align: center;
  margin: 36px auto 0px;
  cursor: pointer;
}
</style>