<template>
  <div class="xwph-banxin">
    <div class="left" v-for="(item, index) in jinriremen" :key="index" @click="jinri(item.id)">
      <div
        class="leftimg"
        :style="{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }"
        v-lazy:background-image="{src: baseUrl + item.imgUrl}"
      >
      </div>
      <div class="h3">{{item.title}}</div>
      <!-- <div class="t1">已有1121人观看</div> -->
      <div class="content">
       {{item.briefInfo}}
      </div>
    </div>
    <div class="right">
      <div class="t2">{{ $t('content.newsRankings') }}</div>
      <div class="more" @click="chakangd">{{ $t('content.viewMore') }}>></div>
      <div class="line"></div>
      <div class="detailbox">
        <div class="detail" v-for="(item, index) in xinwenph" :key="index" @click="jinri(item.id)">
          <div class="num1"></div>
          <div
            class="img"
            :style="{
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }"
            v-lazy:background-image="{src: baseUrl + item.imgUrl}"
          >
          </div>
          <div class="detailbox-right">
            <div class="h4">{{ item.title }}</div>
            <div class="content">
              {{ item.briefInfo }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xinwenpaihang: "新闻排行",
      chakangengduo: "查看更多",
      id:0,
      xinwenph: [],
      jinriremen: [],
      baseUrl: "http://qiniu.image.citgroup.cn/hebei/zhangjiakou/cms",
    };
  },
  mounted() {
    this.home();
    this.homejinriremen();
  },
  methods: {
    jinri(id) {
      // this.$router.push({
      //   path: '/xinwenpaihangmore',
      //   query: {
      //     id
      //   },
      // });
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/noticeApi/getDetail/'+id
        },
      });
    },
    chakangd() {
      this.$router.push("/chakangd").catch(() => {});
    },
    async home() {
      let result = await this.$http({
        url: "/api/noticeApi?cxfl=8&classify=36&size=5&page=1",
        method: "get",
      });
      console.log(result);
      this.xinwenph = result.data.data;
      console.log(this.xinwenph);
    },
    async homejinriremen() {
      let result = await this.$http({
        url: "/api/noticeApi?cxfl=8&classify=36&size=1&page=1&isImport=1",
        method: "get",
      });
      console.log(result);
      this.jinriremen = result.data.data;
      console.log(this.jinriremen);
    },
  },
};
</script>

<style>
.xwph-banxin {
  width: 1200px;
  height: 600px;
  /* background: thistle; */
  margin: 49px auto 0px;
  position: relative;
}
.xwph-banxin .left {
  width: 613px;
  height: 600px;
  /* background: #fe6060; */
  position: relative;
  cursor: pointer;
}
.xwph-banxin .left .leftimg {
  width: 613px;
  height: 417px;
  background: steelblue;
}
.xwph-banxin .left .h3 {
  /* width: 180px; */
  /* height: 29px; */
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #343434;
  line-height: 77px;
  overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap;
}
.xwph-banxin .left .t1 {
  width: 170px;
  height: 20px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 77px;
  position: absolute;
  top: 420px;
  right: 0px;
}
.xwph-banxin .left .content {
  width: 606px;
  height: 103px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 30px;
  position: absolute;
  top: 490px;
  left: 0px;
}
.xwph-banxin .right {
  width: 560px;
  height: 600px;
  /* background: tomato; */
  position: absolute;
  right: 0px;
  top: 0px;
}
.xwph-banxin .right .t2 {
  /* width: 120px; */
  height: 25px;
  font-size: 30px;
  font-weight: bold;
  color: #343434;
  line-height: 77px;
  margin-top: -21px;
  margin-left: 25px;
}
.xwph-banxin .right .more {
  width: 94px;
  height: 14px;
  font-size: 17px;
  font-family: 58;
  font-weight: bold;
  color: #343434;
  line-height: 77px;
  margin-top: -18px;
  margin-left: 351px;
  cursor: pointer;
}
.xwph-banxin .right .line {
  width: 392px;
  height: 1px;
  border: 1px solid #bfbfbf;
  margin-top: 47px;
  margin-left: 25px;
}
.xwph-banxin .right .detailbox {
  width: 100%;
  height: 557px;
  /* background: wheat; */
  margin-top: 43px;
}
.xwph-banxin .right .detailbox .detail {
  width: 100%;
  height: 93px;
  margin-bottom: 7px;
}
.xwph-banxin .right .detailbox .detail .num1 {
  width: 17px;
  height: 17px;
  /* background: #fe6060; */
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 17px;
  text-align: center;
}
.xwph-banxin .right .detailbox .detail .img {
  width: 171px;
  height: 93px;
  background: #fe6060;
  margin-left: 35px;
  margin-top: -17px;
}
.xwph-banxin .right .detailbox .detail .detailbox-right {
  height: 82px;
  width: 338px;
  /* background: red; */
  margin-left: 221px;
  margin-top: -88px;
}
.xwph-banxin .right .detailbox .detail .detailbox-right .h4 {
  /* width: 108px;
  height: 18px; */
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #343434;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.xwph-banxin .right .detailbox .detail .detailbox-right .content {
  width: 311px;
  /* height: 38px; */
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 23px;
  margin-top: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  cursor: pointer;
}
</style>