import { render, staticRenderFns } from "./mrtj.vue?vue&type=template&id=839fa5ae&"
import script from "./mrtj.vue?vue&type=script&lang=js&"
export * from "./mrtj.vue?vue&type=script&lang=js&"
import style0 from "./mrtj.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports