<template>
  <div class="tjxl-banxin">
    <div class="tjxl-title">{{ $t('content.recommendedCircuit') }}</div>
    <div class="content" v-for="(item, index) in tuijianxianlu"
        :key="index">
      <div class="img" 
        :style="{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }"
        v-lazy:background-image="{src: baseUrl + item.imgUrl}"
      >
      </div>
      <div class="t1">
        <div class="h4">{{item.title}}</div>
        <div class="t2">
        {{item.briefInfo}}
        </div>
        <div class="morelocation" @click="tuijxianlu(item.id)">{{ $t('content.viewMore') }}</div>
      </div>
    </div>
    <div class="blocklist">
      <el-pagination
        :page-size="size"
        layout="prev, pager, next"
        :total="totalSize"
        @current-change="currentChange()"
        :current-page.sync="currentPage">
      </el-pagination>
    </div>
    <!-- <div class="content1" >
      <div class="img"></div>
      <div class="t1">
        <div class="h4">中国张家口</div>
        <div class="t2">
          张家口市，又称“张垣”“武城”，是河北省下辖地级市，地处河北省西北部，京、冀、晋、蒙四省市交界处，是京津冀（环渤海）经济圈和冀晋蒙（外长城）经济圈的交汇点。是冀西北地区的中心城市，连接京津、沟通晋蒙的交通枢纽。
          [1]
          介于东经113°50′～116°30′，北纬39°30′～42°10′之间。全市南北长289.2千米，东西宽216.2千米，总面积3.68万平方千米。
          [2] 截至2019年，张家口市常住人口442.33万人，下辖6区10县。
        </div>
        <div class="more">查看更多</div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      tuijianxianlumes:'推荐线路',
      tuijianxianlu: [],
      baseUrl: "http://qiniu.image.citgroup.cn/hebei/zhangjiakou/cms",
      currentPage: 1,
      totalSize:0,
      size: 3,
    }
  },
   mounted() {
    this.home();
  },
  methods: {
    tuijxianlu(id) {
      // this.$router.push({
      //   path: "/publicdqmjdetails",
      //   query: {
      //     id,
      //   },
      // });
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/travelStoreApi/getDetail/'+id
        },
      });
    },
    // tuijiangongluemore() {
    //   this.$router.push('/tuijiangongluemore').catch(()=>{})
    // },
    async home() {
      let result = await this.$http({
        // url: "/api/travelStoreApi?cxfl=8&classify=41&size=3",
        url: `/api/travelStoreApi?cxfl=8&classify=41&size=${this.size}&page=${this.currentPage}`,
        method: "get",
      });
      console.log(result);
      this.tuijianxianlu = result.data.data;
      console.log(this.tuijianxianlu)
      this.totalSize = result.data.totalSize
    },
  },
};
</script>

<style>
.tjxl-banxin {
  width: 1200px;
  height: 1450px;
  /* background: pink; */
  margin: 100px auto 0;
  overflow: hidden;
}
.tjxl-banxin .tjxl-title {
  /* width: 214px; */
  height: 48px;
  line-height: 126px;
  font-size: 51px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 51px;
  margin: 104px auto 63px;
  text-align: center;
}
.tjxl-banxin .content {
  width: 1200px;
  height: 298px;
  /* background: blue; */
  margin-bottom: 82px;
  position: relative;
}
.tjxl-banxin .content .img {
  width: 400px;
  height: 298px;
  background: #666666;
}
.tjxl-banxin .content1 {
  width: 1200px;
  height: 298px;
  /* background: blue; */
  margin-bottom: 82px;
  position: relative;
}
.tjxl-banxin .content1 .img {
  width: 400px;
  height: 298px;
  background: #666666;
  position: absolute;
  right: 0px;
  top: 0px;
}
.tjxl-banxin .content .t1 {
  width: 686px;
  height: 280px;
  /* background: chocolate; */
  position: absolute;
  left: 439px;
  top: 9px;
}
.tjxl-banxin .content1 .t1 {
  width: 686px;
  height: 280px;
  /* background: chocolate; */
  position: absolute;
  right: 439px;
  top: 9px;
}
.tjxl-banxin .content .t1 .h4,
.tjxl-banxin .content1 .t1 .h4 {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ce5a4e;
  line-height: 24px;
}
.tjxl-banxin .content .t1 .t2,
.tjxl-banxin .content1 .t1 .t2 {
  width: 686px;
  height: 126px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #787878;
  margin-top: 51px;
}
.tjxl-banxin .content1 .t1 .more,  .tjxl-banxin .content .t1 .morelocation{
  width: 90px;
  height: 21px;
  background: #ce5a4e;
  position: absolute;
  left: 0px;
  top: 240px;
  line-height: 21px;
  text-align: center;
  cursor: pointer;
  color: #fff;
}

.blocklist{
  margin-top: 100px;
  text-align: center;
}
.el-pagination {
    width: 1200px;
    height: 40px;
}
.el-pagination .btn-prev {
  height: 40px;
}
.el-pagination .btn-prev i{
  font-size: 20px;
}
.el-pagination .btn-prev:hover {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #ce594d;
  opacity: 0.6;
  border-radius: 50%;
  text-align: center;
}
.el-pagination .btn-next {
  height: 40px;
}
.el-pagination .btn-next i{
  font-size: 20px;
}
.el-pagination .btn-next:hover {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #ce594d;
  opacity: 0.6;
  border-radius: 50%;
  text-align: center;
}
.el-pager {
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.el-pager .number{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #f8f8f8;
    opacity: 0.6;
    border-radius: 50%;
    text-align: center;
    color: #000000 !important;
    font-size: 20px;
    font-weight: 300;
    margin-left: 23px;
}
.el-pager .number:hover{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ce594d;
    opacity: 0.6;
    border-radius: 50%;
    text-align: center;
}
.el-pager .active{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ce594d;
    opacity: 0.6;
    border-radius: 50%;
    text-align: center;
}
</style>