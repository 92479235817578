<template>
  <div>
    <Swiper></Swiper>
    <Ddmj></Ddmj>
    <Djmj></Djmj>
    <Tjxl></Tjxl>
    <Bottombanner></Bottombanner>
  </div>
</template>

<script>
import Swiper from "../swiper/swiper";
import Ddmj from "../ddmj/ddmj";
import Djmj from "../djmj/djmj";
import Tjxl from "../tjxl/tjxl.vue";
import Bottombanner from "../bottombanner/bottombanner";
export default {
  components: {
    Swiper,
    Ddmj,
    Djmj,
    Tjxl,
    Bottombanner
  },
};
</script>

<style>
</style>