<template>
  <div class="zl-banxin">
    <div class="icon"></div>
    <div class="mrtj">{{ $t('content.theGamesAreZone') }}
      <div class="morezl" @click="chakangd">{{ $t('content.viewMore') }}>></div>
    </div>
    <div class="box">
      <div class="boxdetail" v-for="(item, index) in dongaozq" :key="index" @click="jinri(item.id)">
        <div class="img"  
          :style="{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }"
          v-lazy:background-image="{src: baseUrl + item.imgUrl}"
        >
        </div>
        <div class="content">
          <div class="t1">{{item.title}}</div>
          <div class="time">2019-05-20</div>
          <div class="t2">
            {{ item.briefInfo }}
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dongaozhuanqu:'冬奥专区',
      content: "经过365天的实际体验为您精挑细选出最优美尽兴的路线规划",
      dongaozq: [],
      baseUrl: "http://qiniu.image.citgroup.cn/hebei/zhangjiakou/cms",
    };
  },
  mounted() {
    this.home();
  },
  methods: {
    jinri(id) {
      // this.$router.push({
      //   path: '/xinwenpaihangmore',
      //   query: {
      //     id
      //   },
      // });
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/noticeApi/getDetail/'+id
        },
      });
    },
    // tuijiangongluemore() {
    //   this.$router.push('/tuijiangongluemore').catch(()=>{})
    // },
    async home() {
      let result = await this.$http({
        url: "/api/noticeApi?cxfl=8&classify=81&size=4&page=1",
        method: "get",
      });
      console.log(result);
      this.dongaozq = result.data.data;
    },
    chakangd() {
      this.$router.push("/chakangd").catch(() => {});
    },
  },
};
</script>

<style>
.zl-banxin {
  width: 1200px;
  height: 500px;
  /* background: chocolate; */
  margin: 122px auto 0px;
  position: relative;
  margin-bottom: 150px;
}
.zl-banxin .icon {
  width: 9px;
  height: 62px;
  background: #fe6060;
  position: absolute;
  left: 8px;
  top: 0px;
}
.zl-banxin .mrtj {
  /* width: 240px; */
  height: 38px;
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 56px;
  margin-left: 40px;
}
.zl-banxin .box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 450px;
  /* background: red; */
  margin-top: 54px;
}
.zl-banxin .box .boxdetail {
  width: 590px;
  height: 210px;
  /* background: olive; */
  position: relative;
  cursor: pointer;
}
.zl-banxin .box .boxdetail .img {
  width: 247px;
  height: 210px;
  background: peachpuff;
}
.zl-banxin .box .boxdetail .content {
  width: 319px;
  height: 195px;
  position: absolute;
  left: 271px;
  top: 5px;
}
.zl-banxin .box .boxdetail .content .t1 {
  width: 335px;
  /* height: 29px; */
  font-size: 31px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap;
}
.zl-banxin .box .boxdetail .content .time {
  /* width: 117px; */
  height: 16px;
  font-size: 21px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  position: absolute;
  left: 0px;
  top: 46px;
}
.zl-banxin .box .boxdetail .content .t2 {
  width: 314px;
  height: 104px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  margin-top: 56px;
  display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 3;
overflow: hidden;
}

.morezl {
  width: 94px;
  height: 14px;
  font-size: 17px;
  font-family: 58;
  font-weight: bold;
  color: #343434;
  /* line-height: 77px;
  margin-top: -58px;
  margin-left: 171px; */
  cursor: pointer;
  float: right;
}
</style>