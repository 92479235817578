<template>
  <div>
    <div class="bcimg">
      <div class="title">{{ $t('content.aboutUs') }}</div>
      <div class="line"></div>
      <div class="content">
        {{ $t('content.aboutUsContent') }}
      </div>
      <!-- <div class="more">{{more}}</div> -->
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      about:'关于我们',
      content:'中国的美食小吃数不胜数，然而偏偏在河北有这么一个美丽的城市，它的名字叫做张家口。它不仅有宣化古城、暖泉古镇、桥西大境门风景区、沽源塞外庄园等好玩的景点，说到吃也少不了这座文化古都。张家口的城市街道繁华，美食特色小吃也很棒，作为吃货我的在这里已经迫不及待要给大家分享一下张家口都有哪些美味了。说到攸面北方的朋友并不陌生，莜面是一种很好的保健食品，有助于减肥和美容。吃莜面很重要的一点就是作料，正宗的作料有泡菜汤、猪肉汤、鸡汤等等，还有一种就是用醋、香油、盐、味精、葱花、香菜调制成的汤，用莜面沾着吃，非常的好吃。莜面还可以炒着吃和凉拌：炒着吃：锅里放入食用油，油开放入葱，姜，蒜爆炒，加入适量配菜加入调味品，炒至七分熟，将莜面切开放入，待菜熟美味即完成。如此诱人的面食更是受到了更多人的喜欢。',
      more:'查看更多',
    }
  }
};
</script>

<style>
.bcimg {
  width: 100%;
  height: 400px;
  background: skyblue;
  overflow: hidden;
  background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/bcimg.png) no-repeat center center;
  margin-top: 136px;
}
.bcimg .title {
  /* width: 112px; */
  height: 26px;
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 42px;
  margin: 40px auto 25px;
  text-align: center;
}
.bcimg .line {
  width: 60px;
  height: 4px;
  background: #ffffff;
  margin: 50px auto 47px;
}
.bcimg .content {
  width: 1152px;
  height: 120px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
  margin: 47px auto;
  text-align: justify;
}
.bcimg .more {
  width: 200px;
  height: 50px;
  background: #e60012;
  margin: 0px auto;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
}
</style>