<template>
  <div>
    <Swiper></Swiper>
    <Pcms></Pcms>
    <About></About>
    <Msfl></Msfl>
    <Bottombanner></Bottombanner>
  </div>
</template>

<script>
import Swiper from "../swiper/swiper";
import Pcms from "../pcms/pcms.vue";
import About from "../about/about";
import Msfl from "../msfl/msfl";
import Bottombanner from "../bottombanner/bottombanner";
export default {
  components: {
    Swiper,
    Pcms,
    About,
    Msfl,
    Bottombanner
  },
};
</script>

<style>
</style>