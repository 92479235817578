<template>
  <div class="adress">
    <div class="banxin1">
      <div class="h2">{{ $t('content.inTheBeauty') }}</div>
      <div class="navname">
        <div
          :class="{ dqmjisActive: this.dqmjisActive === this.qiaoxiqu }"
          @click="Qiaoxiqu"
        >
          {{ $t('content.qiaoxiqu') }}
        </div>
        <div>|</div>
        <div
          :class="{ dqmjisActive: this.dqmjisActive === this.chongliqu }"
          @click="Chongliqu"
        >
          {{ $t('content.chongliqu') }}
        </div>
        <div>|</div>
        <div
          :class="{ dqmjisActive: this.dqmjisActive === this.huailaixian }"
          @click="Huailaixian"
        >
          {{ $t('content.huailaixian') }}
        </div>
        <div>|</div>
        <div
          :class="{ dqmjisActive: this.dqmjisActive === this.chichengxian }"
          @click="Chichengxian"
        >
          {{ $t('content.chichengxian') }}
        </div>
        <div>|</div>
        <div
          :class="{ dqmjisActive: this.dqmjisActive === this.zhangbeixian }"
          @click="Zhangbeixian"
        >
          {{ $t('content.zhangbeixian') }}
        </div>
      </div>
      <!-- 展示区 -->
      <div class="box">
        <div
          class="box1"
          v-for="(item, index) in dqmjdetailArr"
          :key="index"
          :style="{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }"
          v-lazy:background-image="{src: baseUrl + item.imgUrl}"
          @click="publicdqmjdetailstwo(item.id)"
        >
          <div class="tit">{{ item.title }}</div>
        </div>
      </div>
      <!-- 浏览更多 -->
      <div class="btn" @click="Liulangengduo">{{ $t('content.browseMore') }}</div>
    </div>
  </div>
</template>

<script>
import Swiperone from "../swiperone/swiperone";
export default {
  data() {
    return {
      diqumeijing: "地区美景",
      dqmjisActive: "桥西区",
      qiaoxiqu: "桥西区",
      chongliqu: "崇礼区",
      huailaixian: "怀来县",
      chichengxian: "赤城县",
      zhangbeixian: "张北县",
      liulangengduo: "浏览更多",
      zoneCode: 130703,
      dqmjdetailArr: [],
      baseUrl: "http://qiniu.image.citgroup.cn/hebei/zhangjiakou/cms",
    };
  },
  components: {
    Swiperone,
  },
  methods: {
    Qiaoxiqu(event) {
      console.log(event);
      this.dqmjisActive = event.target.innerText;
      this.zoneCode = 130703;
      this.home();
    },
    Chongliqu(event) {
      // console.log(event)
      this.dqmjisActive = event.target.innerText;
      this.zoneCode = 130709;
      this.home();
    },
    Huailaixian(event) {
      this.dqmjisActive = event.target.innerText;
      this.zoneCode = 130730;
      this.home();
    },
    Chichengxian(event) {
      this.dqmjisActive = event.target.innerText;
      this.zoneCode = 130732;
      this.home();
    },
    Zhangbeixian(event) {
      this.dqmjisActive = event.target.innerText;
      this.zoneCode = 130722;
      this.home();
    },
    publicdqmjdetailstwo(id) {
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/travelStoreApi/getDetail/'+id
        },
      });
    },
    Liulangengduo() {
      this.$router.push("/dqmjmore").catch(() => {});
    },
    async home() {
      let resoult = await this.$http({
        url:
          "/api/travelStoreApi?cxfl=8&classify=42&size=5&zoneCode=" +
          this.zoneCode,
        method: "get",
      });

      this.dqmjdetailArr = resoult.data.data;
      console.log(this.dqmjdetailArr);
    },
  },
  created() {
    this.home();
  },
};
</script>

<style>
/* 地区美景 */
.adress {
  width: 100%;
  height: 798px;
  z-index: 1;
  position: absolute;
}
.adress .banxin1 {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  /* background-color: pink; */
  text-align: center;
}
.adress .banxin1 .h2 {
  height: 84px;
  line-height: 103px;
  /* background-color: green; */
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.adress .banxin1 .navname {
  width: 800px;
  height: 50px;
  line-height: 57px;
  /* background-color: pink; */
  /* margin-left: 321px;
  margin-bottom: 37px;
  margin-top: 33px; */
  margin: 33px auto 37px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  cursor: pointer;
}

.dqmjisActive {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #e60012;
}
.box {
  width: 1200px;
  height: 314px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.box .box1 {
  width: 205px;
  height: 314px;
  background: pink;
}
.box .box1 .tit {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 160px auto;
}
/* 浏览更多 */
.banxin1 .btn {
  background: url(http://qiniu.image.citgroup.cn/hebei/zhangjiakou/icon/btn.png)
    no-repeat;
  width: 227px;
  height: 46px;
  line-height: 46px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #54413c;
  text-align: center;
  margin: 36px auto 0px;
  cursor: pointer;
}
</style>